export function ViewSavingsTransactionController (scope, rootScope, resourceFactory, location, routeParams, $uibModal) {
  scope.transaction = {};
  scope.isExternalTransfer = false;
  scope.showCreditor = false;
  scope.showDebitor = false;
  scope.transferData = {};
  scope.errorMsg = '';
  scope.rawPaymentDetails = {};
  scope.showRawReturn = false;
  scope.rawReturnPaymentDetails = {};
  scope.ableToStopPayment = false;
  scope.displayViewWire = false;
  scope.savingsAccountId = routeParams.accountId;
  const params = { savingsId: routeParams.accountId, transactionId: routeParams.id, associations: 'includeEnrichedData,associatedTransactions' };

  scope.onClickViewTransaction = function () {
    location.path(`/accounttransfers/${scope.transferId}/viewWireTransfer`);
  };

  if (process.env.VUE_APP_SUBLEDGER !== 'evolve') {
    scope.displayViewWire = true;
  }

  function getSavingTransaction () {
    resourceFactory.savingsTrxnsResource.get(params, function (data) {
      scope.transaction = { ...data };
      const transferData = data.transferData;
      if (transferData) {
        scope.rawPaymentDetails = JSON.stringify(transferData.rawPaymentDetails, null, 2);
        if (transferData.rawReturnDetails?.rawBody) {
          scope.showRawReturn = true;
          scope.rawReturnPaymentDetails = JSON.stringify(transferData.rawReturnDetails.rawBody, null, 2);
        }
        scope.transferData = transferData;
        scope.transferId = transferData.id;
        scope.isExternalTransfer = true;
        scope.canBeReturn = transferData.inOrOut === 'IN' &&
          transferData.paymentType === 'ACH' &&
          ['EXECUTION_FAILURE', 'AML_REJECTED', 'EXECUTION_SUCCESS'].includes(transferData.status);
        scope.ableToStopPayment = transferData.stopFutureDebit
          ? false
          : transferData.inOrOut === 'IN' &&
          transferData.paymentType === 'ACH' && transferData.type === 'DEBIT';

        if (transferData.paymentType === 'CARD') {
          const clientId = scope.transferData.clientId;
          const externalCardId = scope.transferData.type === 'DEBIT' ? scope.transferData.debtor.identifier.replace('EXTERNALCARD:', '') : scope.transferData.creditor.identifier.replace('EXTERNALCARD:', '');

          getExternalCardDetail({ clientId, externalCardId });
        }
      }

      if (data.paymentDetailData) {
        scope.cardAuthorization = scope.transaction.paymentDetailData.cardAuthorizationData;
        scope.cardSettlement = scope.transaction.paymentDetailData.settlementData;
        scope.paymentType = data.paymentDetailData.paymentType?.name;
      }
      scope.paymentType = data.paymentDetailData.paymentType?.name;
      if (transferData) {
        scope.transferData = transferData;
        scope.isExternalTransfer = true;
        if ((scope.transferData.inOrOut === 'OUT' && scope.transferData.type === 'CREDIT') ||
            (scope.transferData.inOrOut === 'IN' && scope.transferData.type === 'DEBIT')) {
          scope.showCreditor = true;
        }
        if (scope.transferData.inOrOut === 'IN' && scope.transferData.type === 'CREDIT') {
          scope.showDebitor = true;
        }
      }
    }, handleResponseError);
  }

  scope.changeTransferView = function () {
    scope.showRawReturn = !scope.showRawReturn;
  };

  function stopPaymentCtrl ($scope, $uibModalInstance) {
    $scope.savingsAccountId = routeParams.accountId;
    $scope.paymentType = scope.transaction.paymentDetailData.paymentType.name;
    $scope.transferId = scope.transaction.transferData?.id;

    $scope.stopPaymentSuccess = () => {
      $scope.cancel();
      getSavingTransaction();
      scope.ableToStopPayment = false;
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

  scope.stopPaymentModal = function () {
    $uibModal.open({
      templateUrl: 'stop-payment.html',
      controller: stopPaymentCtrl,
      size: 'lg',
      backdrop: 'static'
    });
  };

  function returnTransferCtrl ($scope, $uibModalInstance) {
    $scope.formData = {
      errorCode: null,
      errorMessage: null
    };
    $scope.selected = null;
    $scope.transferAchReturnErrorCode = [];

    resourceFactory.transferTemplate.get((data) => {
      let results = [];
      const codes = Object.entries(data.achReturnErrorCodes);
      if (codes.length) {
        results = codes.map(codePair => ({ label: `${codePair[0]} - ${codePair[1]}`, value: codePair[0] }));
      }
      $scope.transferAchReturnErrorCode = results;
    });

    $scope.handleCurrentUpdate = (value) => {
      $scope.selected = value;
      $scope.formData.errorCode = $scope.selected;
    };

    $scope.handleConfirmReturnTransfer = function () {
      if (!$scope.formData?.errorCode) return;

      const data = { ...$scope.formData };
      const params = {
        id: scope.transferData.id,
        command: 'cancel'
      };

      resourceFactory.transferResource.save(params, data, function () {
        $scope.cancel();
        getSavingTransaction();
      }, handleResponseError);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.handleReturnTransfer = function () {
    $uibModal.open({
      templateUrl: 'return-transfer.html',
      controller: returnTransferCtrl,
      size: 'lg'
    });
  };

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };

  function getExternalCardDetail (params) {
    resourceFactory.externalCardsResource.get(params, function (data) {
      scope.externalCardDetail = data;
    }, handleResponseError);
  }

  getSavingTransaction();
}

ViewSavingsTransactionController.$inject = ['$scope', '$rootScope', 'ResourceFactory', '$location', '$routeParams', '$uibModal'];
