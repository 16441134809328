export function EditChargeController (scope, resourceFactory, location, routeParams, dateFilter) {
  scope.template = [];
  scope.showdatefield = false;
  scope.repeatEvery = false;
  scope.first = {};
  scope.flag = false;
  scope.showPenalty = true;
  scope.chargeDueDateOnAccountActivation = '';
  scope.isTransfer = false;
  scope.clientClassificationOptions = [];
  scope.isOnlyCredit = false;
  scope.isAtmWithdrawal = false;
  scope.isCreditCard = false;
  scope.showDueDateField = true;
  scope.returnCodes = [];
  scope.formData = {};
  scope.returnCodeHasError = false;

  scope.returnCodeInputHandler = function (value) {
    scope.returnCodeHasError = !value?.length;
    scope.formData.returnCodeValues = value;
  };

  resourceFactory.chargeResource.getCharge({ chargeId: routeParams.id, template: true }, function (data) {
    scope.template = data;
    scope.incomeAccountOptions = data.incomeOrLiabilityAccountOptions.incomeAccountOptions || [];
    scope.liabilityAccountOptions = data.incomeOrLiabilityAccountOptions.liabilityAccountOptions || [];
    scope.incomeAndLiabilityAccountOptions = scope.incomeAccountOptions.concat(scope.liabilityAccountOptions);
    scope.chargeDueDateOnAccountActivation = (data.chargeDueDateOnAccountActivation) ? 'true' : 'false';
    scope.clientClassificationOptions = (data.clientClassificationOptions || []).map((classification) => {
      const displayName = `${classification.codeName.split('-')[1]} | ${classification.name}`;
      return { ...classification, displayName };
    });

    scope.percentChargeCalculationTypeIds = scope.template.chargeCalculationTypeOptions?.filter(type =>
      type.code.includes('chargeCalculationType.percent')).map(type => type.id);

    if (data.chargeAppliesTo.value === 'Loan') {
      scope.chargeTimeTypeOptions = data.loanChargeTimeTypeOptions;
      scope.template.chargeCalculationTypeOptions = scope.template.loanChargeCalculationTypeOptions;
      scope.flag = false;
      scope.showFrequencyOptions = true;
      scope.isTransfer = false;
    } else if (data.chargeAppliesTo.value === 'Savings') {
      scope.chargeTimeTypeOptions = data.savingsChargeTimeTypeOptions;
      scope.template.chargeCalculationTypeOptions = scope.template.savingsChargeCalculationTypeOptions;
      scope.flag = true;
      scope.showFrequencyOptions = false;
      scope.isTransfer = false;
    } else if (data.chargeAppliesTo.value === 'Shares') {
      scope.showChargePaymentByField = false;
      scope.chargeCalculationTypeOptions = scope.template.shareChargeCalculationTypeOptions;
      scope.chargeTimeTypeOptions = scope.template.shareChargeTimeTypeOptions;
      scope.addfeefrequency = false;
      scope.showGLAccount = false;
      scope.showPenalty = false;
      scope.flag = true;
      scope.isTransfer = false;
    } else if (data.chargeAppliesTo.value === 'Transfer') {
      scope.chargeTimeTypeOptions = data.transferChargeTimeTypeOptions;
      scope.template.chargeCalculationTypeOptions = scope.template.savingsChargeCalculationTypeOptions;
      scope.paymentRailOptions = scope.template.transferPaymentRailsTypes;
      scope.transferTypeOptions = scope.template.transferTypes;
      scope.paymentDirectionOptions = scope.template.paymentDirectionTypes;
      scope.flag = true;
      scope.showFrequencyOptions = false;
      scope.isTransfer = true;
      scope.showdatefield = false;
    } else if (data.chargeAppliesTo.value === 'Credit Card') {
      scope.flag = true;
      scope.template.chargeCalculationTypeOptions = data.creditCardChargeCalculationTypeOptions;
      scope.chargeTimeTypeOptions = scope.template.creditCardChargeTimeTypeOptions;
      scope.showFrequencyOptions = false;
      scope.showGLAccount = false;
      scope.isTransfer = false;
      scope.isCreditCard = true;
    } else {
      scope.flag = true;
      scope.template.chargeCalculationTypeOptions = data.clientChargeCalculationTypeOptions;
      scope.chargeTimeTypeOptions = scope.template.clientChargeTimeTypeOptions;
      scope.showFrequencyOptions = false;
      scope.showGLAccount = true;
      scope.isTransfer = false;
    }
    scope.formData = {
      name: data.name,
      ignoreChargesOnNegativeBalance: data.ignoreChargesOnNegativeBalance,
      collectOnlyTotalDeferCharge: data.collectOnlyTotalDeferCharge,
      active: data.active,
      reverseOnTransferFail: data.reverseOnTransferFail,
      penalty: data.penalty,
      currencyCode: data.currency.code,
      chargeAppliesTo: data.chargeAppliesTo.id,
      chargeTimeType: data.chargeTimeType.id,
      chargeCalculationType: data.chargeCalculationType.id,
      amount: data.amount,
      chargeDueDateOnAccountActivation: (data.chargeDueDateOnAccountActivation) ? 'true' : 'false',
      numberOfExemptedFee: data.numberOfExemptedFee
    };
    if (data.exemptedFeeAmount) {
      scope.formData.exemptedFeeAmount = data.exemptedFeeAmount;
    }

    scope.applyUpfrontCharge = function () {
      if (scope.formData.chargeTimeType === 7 && scope.formData.applyUpfrontCharge) {
        scope.showRadio = false;
      } else {
        scope.showRadio = true;
        scope.formData.applyEndOfPeriod = false;
      }
    };

    scope.applyEndOfPeriodCharge = function () {
      if (scope.formData.chargeTimeType === 7 && scope.formData.applyEndOfPeriod) {
        scope.showRadio = false;
      } else {
        scope.showRadio = true;
        scope.formData.applyUpfrontCharge = false;
      }
    };

    scope.$watch('formData.numberOfExemptedFee', function (value) {
      scope.formData.numberOfExemptedFee = value || 0;
    });
    if (data.chargeTimeType.id === 21 || data.chargeTimeType.id === 22) {
      scope.isAtmWithdrawal = true;
      if (data.clientClassification) {
        scope.formData.clientClassificationId = data.clientClassification.id;
      }
    }
    scope.formData.applyUpfrontCharge = data.applyUpfrontCharge;
    scope.formData.applyEndOfPeriod = data.applyEndOfPeriod;
    scope.refreshChargeDueDateCalculation();
    if (data.incomeOrLiabilityAccount) {
      scope.formData.incomeAccountId = data.incomeOrLiabilityAccount.id;
    }

    if (data.taxGroup) {
      scope.formData.taxGroupId = data.taxGroup.id;
    }

    if (data.feeFrequency) {
      scope.addfeefrequency = 'true';
      scope.formData.feeFrequency = data.feeFrequency.id;
      scope.formData.feeInterval = data.feeInterval;
    }

    // when chargeAppliesTo is savings, below logic is
    // to display 'Due date' field, if chargeTimeType is
    // 'annual fee' or 'monthly fee'
    if (scope.formData.chargeAppliesTo === 2) {
      if (data.chargeTimeType.value === 'Specified due date') {
        if (data.feeOnMonthDay) {
          const actDate = dateFilter(Object.values(data.feeOnMonthDay), 'dd MMM');
          scope.first.date = new Date(actDate);
        }
      }
      if ((data.chargeTimeType.value === 'Annual Fee' || data.chargeTimeType.value === 'Monthly Fee') && !data.chargeDueDateOnAccountActivation) {
        scope.showdatefield = true;
        if (data.feeOnMonthDay) {
          const actDate = dateFilter(Object.values(data.feeOnMonthDay), 'dd MMM');
          scope.first.date = new Date(actDate);
          // to display "Repeats Every" field ,if chargeTimeType is
          // 'monthly fee'
          if (data.chargeTimeType.value === 'Monthly Fee') {
            scope.repeatEvery = true;
            scope.formData.feeInterval = data.feeInterval;
          } else {
            scope.repeatEvery = false;
          }
        }
      } else {
        scope.showdatefield = true;
      }
    } else if (scope.formData.chargeAppliesTo === 5) {
      scope.showdatefield = false;
      scope.formData.paymentRail = data.paymentRail.id;
      scope.formData.paymentDirection = data.paymentDirection;
      scope.formData.transferType = data.transferType;
      scope.formData.reverseOnTransferFail = data.reverseOnTransferFail;
      scope.updateTransferType(scope.formData.paymentRail);
      if (data.clientClassification) {
        scope.formData.clientClassificationId = data.clientClassification.id;
      }
    } else {
      scope.formData.chargePaymentMode = data.chargePaymentMode.id;
    }
    if (data.maxCap) {
      scope.formData.maxCap = data.maxCap;
    }
    if (data.minCap) {
      scope.formData.minCap = data.minCap;
    }

    resourceFactory.chargeResource.getCharge({ chargeId: routeParams.id }, function (chargeData) {
      scope.formData.returnCodeValues = chargeData.returnCodeValues;

      resourceFactory.returnCodes.get(function (returnCodesData) {
        const { allReturnCodeMap, allAssignedReturnCode: { usedReturnCodeList } } = returnCodesData;
        const usableCodes = Object.keys(allReturnCodeMap).filter(code => !usedReturnCodeList.includes(code) || scope.formData.returnCodeValues?.includes(code));
        scope.returnCodes = usableCodes.map(value => ({ value, label: `${value} - ${allReturnCodeMap[value]}` }));
      });
    });
  });

  scope.handleIgnoreChargesOnNegativeBalanceChange = function () {
    if (!scope.formData.ignoreChargesOnNegativeBalance) { scope.formData.collectOnlyTotalDeferCharge = false; }
  };

  scope.$watch('chargeDueDateOnAccountActivation', function (value) {
    scope.showdatefield = !(value === 'true');
  });

  scope.refreshChargeDueDateCalculation = function () {
    for (const i in scope.template.chargeTimeTypeOptions) {
      if (scope.formData.chargeTimeType === scope.template.chargeTimeTypeOptions[i].id && scope.template.chargeTimeTypeOptions[i].value === 'Weekly Fee') {
        scope.showdatefield = false;
        break;
      } else if (scope.formData.chargeTimeType === scope.template.chargeTimeTypeOptions[i].id && scope.template.chargeTimeTypeOptions[i].value === 'Specified due date') {
        scope.showdatefield = true;
        break;
      }
    }
  };
  // when chargeAppliesTo is savings, below logic is
  // to display 'Due date' field, if chargeTimeType is
  // 'annual fee' or 'monthly fee'
  scope.chargeTimeChange = function (chargeTimeType) {
    if ((chargeTimeType === 12) && (scope.template.chargeAppliesTo.value === 'Loan')) {
      scope.showFrequencyOptions = false;
    } else {
      scope.showFrequencyOptions = true;
    }
    if (chargeTimeType === 21 || chargeTimeType === 22) {
      scope.isAtmWithdrawal = true;
    } else {
      scope.isAtmWithdrawal = false;
    }
    if (scope.formData.chargeAppliesTo === 2) {
      for (const i in scope.template.chargeTimeTypeOptions) {
        if (chargeTimeType === scope.template.chargeTimeTypeOptions[i].id) {
          if ((scope.template.chargeTimeTypeOptions[i].value === 'Annual Fee' ||
            scope.template.chargeTimeTypeOptions[i].value === 'Monthly Fee') && scope.chargeDueDateOnAccountActivation === 'false') {
            scope.showdatefield = true;
            // to show 'repeats every' field for monthly fee
            if (scope.template.chargeTimeTypeOptions[i].value === 'Monthly Fee') {
              scope.repeatEvery = true;
            } else {
              scope.repeatEvery = false;
            }
          } else {
            scope.showdatefield = false;
          }
        }
      }
    }
  };

  scope.filterChargeCalculations = function (chargeTimeType) {
    return function (item) {
      if (chargeTimeType === 12 && ((item.id === 3) || (item.id === 4))) {
        return false;
      }
      if (chargeTimeType !== 12 && item.id === 5) {
        return false;
      }
      return true;
    };
  };
  scope.resetTransfer = function (chargeTimeType) {
    delete this.formData.paymentRail;
    delete this.formData.paymentDirection;
    delete this.formData.transferType;
    if (!(chargeTimeType === 21 || chargeTimeType === 22)) {
      delete this.formData.clientClassificationId;
    }
  };
  scope.resetTransfer = function (chargeTimeType) {
    delete this.formData.paymentRail;
    delete this.formData.paymentDirection;
    delete this.formData.transferType;
    delete this.formData.reverseOnTransferFail;
    if (!(chargeTimeType === 21 || chargeTimeType === 22)) {
      delete this.formData.clientClassificationId;
    }
  };

  scope.isUpfrontCharge = function () {
    if (scope.formData?.chargeAppliesTo === 2) {
      if (scope.formData.chargeTimeType === 6 || scope.formData.chargeTimeType === 7 || scope.formData.chargeTimeType === 11) {
        return true;
      }
    }
    return false;
  };

  scope.resetMinMax = function () {
    delete this.formData.minCap;
    delete this.formData.maxCap;
  };

  scope.updateTransferType = function (id) {
    scope.isOnlyCredit = false;
    for (const i in scope.paymentRailOptions) {
      if (scope.paymentRailOptions[i].id === id) {
        if (!(scope.paymentRailOptions[i].name === 'ACH' || scope.paymentRailOptions[i].name === 'CARD')) {
          scope.formData.transferType = 'CREDIT';
          scope.isOnlyCredit = true;
        }
      }
    }
  };

  scope.isAllowMinMax = function (formData) {
    return scope.percentChargeCalculationTypeIds?.includes(formData.chargeCalculationType);
  };

  scope.submit = function () {
    if (!(scope.formData.chargeAppliesTo === 5 && scope.formData.chargeTimeType === 32)) delete scope.formData.returnCodeValues;
    else if (!scope.formData.returnCodeValues?.length) {
      scope.returnCodeHasError = true;
      return;
    }

    this.formData.chargeDueDateOnAccountActivation = (scope.chargeDueDateOnAccountActivation === 'true');
    if (scope.formData.chargeAppliesTo === 2) {
      if (scope.showdatefield === true) {
        const reqDate = dateFilter(scope.first.date, 'dd MMM');
        this.formData.monthDayFormat = 'dd MMM';
        this.formData.feeOnMonthDay = reqDate;
      }
    } else if (scope.addfeefrequency === 'false') {
      scope.formData.feeFrequency = null;
      scope.formData.feeInterval = null;
    }
    const isUpfront = scope.isUpfrontCharge();
    if (!isUpfront) {
      delete this.formData.applyUpfrontCharge;
      delete this.formData.applyEndOfPeriod;
    }

    if (!scope.isAllowMinMax(this.formData)) {
      scope.resetMinMax();
    }
    if (!scope.isTransfer) {
      scope.resetTransfer(this.formData.chargeTimeType);
    }

    this.formData.locale = scope.optlang.code;
    this.formData.active = this.formData.active || false;
    this.formData.penalty = this.formData.penalty || false;
    resourceFactory.chargeResource.update({ chargeId: routeParams.id }, this.formData, function (data) {
      location.path(`/viewcharge/${data.resourceId}`);
    });
  };
}

EditChargeController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', 'dateFilter'];
